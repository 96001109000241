@import '../../variable.scss';
@import '../../stylesheet/base.scss';



.trainerHeader{
    background-color:$green-bg;
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    z-index: 20 !important;
    top: 0;
    // width: 100%;
    width: 100vw;
    @include for-mobile-475-down {
        flex-direction: column;
    }
    .logo {
        justify-content: flex-start;
        display: flex;
        align-items: center;
        h1 {
        //   padding-left: 1.6rem;
        //   font-size: 2.5rem;
        font-size: 25px;
          margin: 0;    
          align-self: center;
          font-weight: 600;
        }
    }
    .navbar {
        display: flex;
        flex-direction: row;
        flex: 1;
        justify-content: center;
        @include for-mobile-992-max {
            display: none;
        }
        .navbar-element {
            cursor: pointer;
            color: $primary-text;
            padding: 10px;
            padding-left: 20px;
            padding-right: 20px;
            font-size: $f-18;
            font-weight: $f-weight-5;
        }
            
    }
    .connected { font-size: 18px; 
        flex:1; 
        display: flex; 
        align-items: center;
        justify-content: flex-end;
        @include for-mobile-475-down {
            display: none;
        }
        .connected-btn {
            padding: 10px; 
            border: 2px solid white;
            border-radius: 8px;
            font-weight: $f-weight-5;
            font-size: $f-16;
            font-weight: $f-weight-5;
            }
    }
}
.aboutSection{
    .container{
            min-height: 100vh;
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
            margin-top: 30px;
            @include for-mobile-475-down {
                flex-direction: column;
            }
            .connect-app{
                display: none;
                flex-direction: row;
                justify-content: center;
                margin-top: 50px;
                @include for-mobile-475-down {
                    display: flex;
                }
                a{
                    margin: 10px
                }
            }

    }
    .infoContainer{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin-bottom: 50px;
        margin-top: 50px;
        @include for-mobile-475-down {
            flex-direction: column;
            margin-top: 0;
        }
        .infoBlock{
            margin: 10px;
            padding: 10px;
            border-radius: 8px;
            font-weight: $f-weight-7;
            font-size: $f-24;
            text-align: center;
            div{
                font-weight: $f-weight-6;
            }
        }
    }
    .testimonial{
        // background-color: $cyan-bg;
        align-items: flex-start;
        justify-content: center;
        display:flex;
        padding: 0 2rem;
        border-radius: 1rem;
        // position: relative;
        p{
            padding: 1.5rem 2rem;
            font-size: $f-14;
            text-align: center;
            // box-shadow: 2px 2px 25px rgba(0,0,0,0.24);
            margin: 0;
            border-radius: 1rem;
        }
        img{
            // position: absolute;
            height: 5rem;
            top: -0.2rem;
        }
        .left-quote{
            left: 1.5rem;
        }
        .right-quote{
            right: 1.5rem;
        }
    }
    .noData{
        padding: 7rem 0 5rem;
        color: $grey-text;
        font-size: 7rem;
        font-weight: 600;
        text-align: center;
    }
}
.qualification{
   background-color: $secondary-bg;
    margin: 50px 0;
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    min-height: 100vh;
    justify-content: space-around;
    align-items: center;
    .title{
        color: black;
        text-align: center;
        font-size: 4rem;
        font-weight: $f-weight-5;
    }
    .certicate-image{
        height: 34rem;
        width: 100%;
        object-fit: contain;
    }
    .experties{
        border-radius: 8px;
        padding: 10px;
        margin: 10px;
        font-weight: 500;
        font-size: 14px;
    }
}
