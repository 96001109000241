@import '../../../variable.scss';

.btn-evolv {
    background: $green-bg;
    border-radius: 6px;
    color: $primary-text;
    width: 100%;
    border: 0;
    padding: 10px;
    font-weight: 700;
    transition: all 0.4s ease;
}

.btn-evolv.btn-small {
    font-size: 10px;
}

.button-icon {
    height: 17px;
    margin-right: 10px;
    margin-bottom: 2px;
}

.btn-black:disabled {
    background-color: #575656;
}

.btn-green {
    background-color: rgba(112, 252, 209, 1);
    color: black;
}

.btn-red {
    background-color: red;
    color: white;
}

.upload-button-icon {
    height: 17px;
    margin-right: 10px;
    margin-bottom: 2px;
}
