@import '../../variable.scss';
@import '../../stylesheet/base.scss';
.t-container{
    min-height: 100vh;
    background-color: $secondary-bg;
    color: rgba(32, 33, 36, 1);
    h1{
        padding: 7rem 0 5rem;
        color: $grey-text;
        font-size: 7rem;
        font-weight: 600;
        text-align: center;
    }
.transformation-container{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    
    color: rgba(32, 33, 36, 1);
    padding: 4rem 2rem;
    h2{
        // font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 4.2rem;
        line-height: 9.2rem;
        text-transform: uppercase;
        letter-spacing: 0.07em;
    }
    h3{
        // font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 2.4rem;
        line-height: 3rem;
        letter-spacing: 0.04em;
        direction: ltr;
    }
    h4{
        // font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 2rem;
        line-height: 1rem;
    }
    .user-info{
        display: grid;
        text-align:center;
        min-width: 24vw;
        h2{
            font-size: clamp(2rem,4.2rem,4.2rem);
        }
        .btn{
            margin: auto;
            width: fit-content;
        }
        .btn:hover{
            cursor:pointer;
        }
    }
    .user-data{
        display:grid;
        min-width: 60vw;
        gap:3rem;
        .transformation{
            min-width:70vw;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            text-align: center;
            gap: 1rem;
            div{
                h3{
                    padding: 1rem 0 0.7rem;
                }
                img{
                    height: 34rem;
                    width: 55rem;
                    border-radius:2rem;
                    object-fit: contain;
                    box-shadow: 1rem 0.8rem 1.8rem rgba(0, 0, 0, 0.3);
                }
            }
        }
        .testimonial{
            justify-self: center;
            background-color: $cyan-bg;
            display:flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 1rem;
            position: relative;
            p{
                padding: 1.5rem 6rem;
                font-size: 2.1rem;
                text-align: center;
                box-shadow: 2px 2px 25px rgba(0,0,0,0.24);
                margin: 0;
                border-radius: 1rem;
            }
            img{
                position: absolute;
                height: 9.4rem;
                top: -2rem;
            }
            .left-quote{
                left: -2.7rem;
            }
            .right-quote{
                right: -3.7rem;
            }
        }
    }
}
}

@media (max-width: 920px) {
    .transformation-container{
        min-height: auto;
        flex-direction: column;
        // padding-bottom: 2.6rem;
        padding: 10rem 0;
        h2{
            font-size: 4rem;
        }
        h3{
            font-size: 2rem;
        }
        h4{
            font-size: 2rem;
        }
        .user-info{
            grid-template-columns: 1fr 1fr 1fr;
            direction: rtl;
            .btn{
                transform: rotate(90deg);
            }
        }
    }
}