@import "./../../stylesheet/base.scss";
@import "../../variable.scss";

.footer-container {
  overflow-x: hidden;
  background-color: $primary-bg;
  color: $primary-text;
  height: fit-content;
  font-size: 1.6rem;
  padding-top: 20px !important;
  position: relative;
  z-index: 10;
  h6 {
    font-size: 16px;
  }
  .logo-tagline-container {
    position: relative;
    z-index: 1;
    padding-left: 16px;
    padding-right: 16px;
  }

  .footer-grid {
    display: grid;
    justify-items: center;
    grid-template-columns: 1fr 1fr 1fr;
    margin-left: 5%;
  }

  @media (max-width: 800px) {
    .footer-grid {
      margin-left: auto;
      grid-template-columns: 1fr 1fr;
    }
    .footer-links {
      justify-self: flex-start;
    }
  }
  @media (max-width: 480px) {
    .footer-grid {
      justify-items: flex-start;
      grid-template-columns: 1fr;
    }
  }

  .footer-row {
    display: flex;
    font-size: 8pt;
    justify-content: space-between;
    flex-wrap: wrap;
    @include for-mobile-992-max {
      justify-content: space-around;
    }
    .dmca-footer {
      display: flex;
      align-items: flex-end;
      padding: 5px;
    }

    .right-reserved-footer {
      display: flex;
      align-items: flex-end;
      padding: 8px;
      margin-left: 15%;
      > p {
        font-size: 8pt;
        margin-bottom: 0;
      }
    }
    @media (max-width: 800px) {
      .right-reserved-footer {
        margin-left: auto;
      }
    }
    @media (max-width: 500px) {
      .right-reserved-footer {
        margin-left: 0;
      }
    }
    .payment-container {
      display: flex;
      > * {
        padding: 5px;
      }

      .payment-method {
        display: flex;
        align-items: flex-end;
      }
      .payment-method-text {
        margin-bottom: 2px !important;
        display: flex;
        align-items: flex-end;
      }
    }

    .policies-footer {
      padding-left: 10px;
      padding-right: 10px;
      text-align: center;
      > * {
        margin: auto;
        font-size: 12px !important;
        font-weight: 600 !important;
      }
    }
  }

  .footer-z {
    z-index: 10000;
  }

  .links-container {
    font-size: 13px;
    font-weight: 500;
  }

  .footer-text {
    display: inline-block;
    margin-bottom: 30px;
    text-align: left;

    .text {
      font-size: 12px;
      font-weight: 600;
    }
  }

  .stripes {
    z-index: 0;
    position: absolute;
    height: 3px;
    width: 100%;
    background-color: $green-1;
    animation-iteration-count: 1;
  }

  .stripes-bottom {
    transform: translateY(10px);
    width: 25%;
  }

  .stripes-middle {
    transform: translateY(0px);
    width: 30%;
  }

  .stripes-top {
    transform: translateY(-10px);
    width: 35%;
  }

  .stripes-left {
    left: 0;
  }

  .stripes-right {
    right: 0;
  }

  .logo-icon-container {
    z-index: 1;
    width: 100%;

    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px;
  }

  .logo-icon {
    height: 6rem;
  }

  .remove-a-decoration:hover {
    color: #7c7c7c;
  }

  /* left side */

  .stripes-top-left {
    animation: in-view-top-left 0.8s;
  }

  @keyframes in-view-top-left {
    30% {
      transform: translate(-100px, -10px);
    }
  }

  .stripes-middle-left {
    animation: in-view-middle-left 1s;
  }

  @keyframes in-view-middle-left {
    30% {
      transform: translate(-100px, 0px);
    }
  }

  .stripes-bottom-left {
    animation: in-view-bottom-left 1.2s;
  }

  @keyframes in-view-bottom-left {
    30% {
      transform: translate(-100px, 10px);
    }
  }

  /* right side */

  .stripes-top-right {
    animation: in-view-top-right 0.8s;
  }

  @keyframes in-view-top-right {
    30% {
      transform: translate(100px, -10px);
    }
  }

  .stripes-middle-right {
    animation: in-view-middle-right 1s;
  }

  @keyframes in-view-middle-right {
    30% {
      transform: translate(100px, 0px);
    }
  }

  .stripes-bottom-right {
    animation: in-view-bottom-right 1.2s;
  }

  @keyframes in-view-bottom-right {
    30% {
      transform: translate(100px, 10px);
    }
  }

  .social-icon-horizontal,
  .social-icon-vertical {
    max-width: 40px;
    max-height: 40px;
    margin-top: 15px;
    cursor: pointer;
    display: block;
    margin-left: 10px;
    margin-right: 10px;
    transition: transform 0.3s ease;
  }

  .social-icon-horizontal {
    transform: translateY(0px);
    width: 45px;
  }

  .social-icon-horizontal-small {
    max-width: 18px;
    max-height: 18px;
  }

  .social-icon-horizontal:hover:not([disabled]) {
    transform: translateY(-10px);
  }
}
