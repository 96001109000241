@import "../../../variable.scss";

.testimonial-container{
    background-color: $secondary-bg;
    min-height: 100vh;
    // display: grid;
    // justify-content: center;
    padding: 4rem 2rem 8rem;
    h1{
        padding: 7rem 0 10rem;
        color: $grey-text;
        font-size: 7rem;
        font-weight: 600;
        text-align: center;
    }
    .slide{
      height: 26rem;
      width: 42rem;
      background-color: black;
      border-radius: 2.2rem;
      border: 0;
    }
    .slide-thumb{
      object-fit: cover;
      height: inherit;
      width: inherit;
      border-radius: 2.2rem;
    }
    .yt-icon{
      cursor: pointer;
      position: absolute;
      height: 9rem;
      width: 9rem;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      pointer-events: none;
    }
    .iframe-wrapper{
      position: relative;
      padding-bottom: 56.25%;
      padding-top: 2.5rem;
      height: inherit;
      width: inherit;
    }
    .iframe-wrapper iframe{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 2.2rem;
      border: 0;
    }

    .swiper{
      max-width: 75vw;
      overflow: unset;
    }
    .swiper-slide{
      display: flex;
      justify-content: center;
      border-radius: 2.2rem;
      transform: scale(0.82) !important;
      opacity: 0;
    }
    .swiper-slide-visible{
      z-index: 2 !important;
      opacity: 0.48;
    }

    .swiper-slide-active{
      box-shadow: 2px 2px 25px black;
      transform: scale(1.15) !important;
      z-index: 5 !important;
      opacity: 1;
      .yt-icon{
        pointer-events: unset;
      }
    }
    .swiper-pagination{
      transform: translateY(9rem);
      color: white;
      z-index: 10;
    }
    .swiper-button-next, .swiper-button-prev{
      font-size: 5rem !important;
      color: white;
    }
    .swiper-pagination-bullet , .swiper-pagination-bullet-active{
      background-color: white;
      width: 1rem;
      height: 1rem;
    }
}
.videoDescription{
  background-color: rgb(34, 34, 34);
  color: white;
  opacity: 0.7;
  padding: 4px 8px;
  position: absolute;
  bottom: 0;
  left: 40%;
  transform: translate(-40%, 0%);
  text-align: center;
}
.popmodal{
  padding: 2rem;
  .modal-content{
    background-color: unset;
  }
  iframe{
    min-height: 40rem;
    border: 0;
  }
}

@media (max-width: 920px) {
    .testimonial-container{
        min-height: auto;
        gap: 7rem;
        margin: 10rem 0 15rem;
        .slide{
          width: 40vw;
          height: 25vw;
        }    
        .swiper{
          max-width: 90vw;
        }    
        .swiper-slide-active{
          transform: scale(1.4) !important;
        }
        .swiper-pagination{
          transform: translateY(12rem);
        }
    }
}