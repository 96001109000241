@import '../../variable.scss';

.custom-nav{
    display: flex;
    right: 0;
    bottom: 0;
    position: fixed;
    gap: 2.2rem;
    padding: 1rem 2.1rem;
    background: $primary-bg;
    border-radius: 10px 0px 0px 0px;
    z-index:5;
    .nav-element{
        font-size: 1.85rem;
        color: $grey-text;
        margin: 0;
    }
    .nav-element:hover {
        transform: translateY(-0.4rem);
    }
}

.menu-active{
    height: 100vh;
    width: 60vw;
    position: fixed;
    right: 0;
    z-index: 10;
    background: rgba($primary-bg,0.95);
    
    h1{
        width: fit-content;
        margin-left: auto;
        cursor: pointer;
        text-align: right;
        padding-right: 2rem;
        font-size: 5.7rem;
        font-weight: 400;
        line-height: 5rem;
    }
    animation: drop-in 0.4s ease-in-out;
}

@keyframes drop-in{
    0%{opacity: 0; transform: translateX(1.8rem);}
    100%{opacity: 1;}
}

.menu-inactive{
    position: fixed;
    padding: 1rem;
    right: 0;
    width: 7rem;
    height: 6.2rem;
    z-index: 10;
    display: grid;
    background: rgba($primary-bg,0.95);
    border-bottom-left-radius: 1rem;
    justify-items: center;
    align-items: center;
    cursor: pointer;
    .ham-strip{
        width: 80%;
        height: 0.3rem;
        background-color: white;
    }    
}
.posabs{
    position: absolute !important;
    margin-top: 3rem;
}

.mobile-nav{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3rem;
    .nav-element{
        color: white;
        font-size: 3.8rem;
        padding: 2rem 0;
    }
}

.logo-nav{
    display: flex;
    margin: 1rem 2rem;
    a{
        display: flex;
        justify-content: center;
        gap: 1rem;
        align-items: center;
        img{
            height: 4.3rem;
        }
        h2{
            color: white;
            margin: 0;
            align-self: center;
        }
    }
}