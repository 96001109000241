@import '../../../variable.scss';
@import '../../../stylesheet/base.scss';

:root{
    --swiper-navigation-size: 4rem
}
.reviews-section{
    min-height: 90vh;
    padding: 20px;
    h1{
        padding: 5rem 0 5rem;
        color: $grey-text;
        font-size: 7rem;
        font-weight: 600;
        text-align: center;
    }
    .review-container{
        .testimonial{
            // background-color: $cyan-bg;
            align-items: flex-start;
            justify-content: center;
            display:flex;
            padding: 0 2rem;
            border-radius: 1rem;
            // position: relative;
            p{
                padding: 1.5rem 2rem;
                font-size: $f-12;
                text-align: center;
                // box-shadow: 2px 2px 25px rgba(0,0,0,0.24);
                margin: 0;
                border-radius: 1rem;
            }
            img{
                // position: absolute;
                height: 3rem;
                top: -0.2rem;
            }
            .left-quote{
                left: 1.5rem;
            }
            .right-quote{
                right: 1.5rem;
            }
        }
        .review-rating{
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-content: center;
            align-items: center;
        }
        .star-filled{
            filter: invert(0.6) sepia(1) saturate(5) hue-rotate(15deg);
        }
    }
    .swiper{
        // max-width: 75vw;
        margin-top: 60px;
        z-index: 0;
        margin-bottom: 60px;
        overflow: unset;
        @include for-mobile-475-down {
            max-width: 100%;
        }
      }
    
    .swiper-button-prev, .swiper-button-next{
        color: $primary-text;
        height: 2rem;
        width: 2rem;
    }

    .swiper-pagination-bullet{
        background-color: $light-grey;
    }
    .swiper-pagination-bullet-active{
        background-color: $primary-text
    }
}