/*font*/
$f-8: 8px;
$f-9: 9px;
$f-10: 10px;
$f-11: 11px;
$f-12: 12px;
$f-13: 13px;
$f-14: 14px;
$f-15: 15px;
$f-16: 16px;
$f-18: 18px;
$f-20: 20px;
$f-21: 21px;
$f-22: 22px;
$f-24: 24px;
$f-25: 25px;
$f-26: 26px;
$f-27: 27px;
$f-28: 28px;
$f-30: 30px;
$f-32: 32px;
$f-36: 36px;
$f-40: 40px;
$f-42: 42px;
$f-44: 44px;
$f-47: 47px;
$f-48: 48px;
$f-50: 50px;
$f-56: 56px;
$f-58: 58px;
$f-60: 60px;
$f-75: 75px;

$f-weight-1: 100;
$f-weight-2: 200;
$f-weight-3: 300;
$f-weight-4: 400;
$f-weight-5: 500;
$f-weight-6: 600;
$f-weight-7: 700;
$f-style: normal;

$mbtom130: 130px;

$f-size-9: $f-9;
$f-size-10: $f-10;
$f-size-11: $f-11;
$f-size-12: $f-12;
$f-size-13: $f-13;
$f-size-15: $f-15;
$f-size-14: $f-14;
$f-size-16: $f-16;
$f-size-18: $f-18;
$f-size-21: $f-21;
$f-size-22: $f-22;
$f-size-24: $f-24;
$f-size-20: $f-20;
$f-size-28: $f-28;
$f-size-25: $f-25;
$f-size-32: $f-32;
$f-size-36: $f-36;
$f-size-44: $f-44;
$f-size-56: $f-56;
$f-size-58: $f-58;
$f-size-75: $f-75;

/*media-query*/
$mg-Bigdesktop-max: 3000px;
$mg-Bigdesktop-min: 1921px;
$mg-Bigdesktop-max-up: 1920px;
$mg-desktop-max-up: 1400px;
$mg-desktop-max: 1340px;
$mg-desktop-mid: 1200px;
$mg-desktop-min: 1025px;

$mg-tablet-max: 1024px;
$mg-tablet-mid: 992px;
$mg-tablet-min: 768px;

$mg-mobile-max: 767px;
$mg-mobile-mid: 568px;
$mg-mobile-min: 420px;

$mg-desktop-1199: 1199px;
$mg-desktop-1083: 1083px;
$mg-desktop-1380: 1380px;
$mg-desktop-1920: 1920px;
$mg-desktop-1180: 1180px;
$mg-tablet-1023: 1023px;
$mg-mobile-992: 992px;
$mg-tablet-810: 810px;
$mg-mobile-767: 767px;
$mg-tablet-769: 769px;
$mg-mobile-600: 600px;
$mg-mobile-576: 576px;
$mg-mobile-567: 567px;
$mg-mobile-540: 540px;
$mg-mobile-481: 481px;
$mg-mobile-415: 415px;
$mg-mobile-414: 414px;
$mg-mobile-475: 475px;
$mg-mobile-375: 375px;
$mg-mobile-374: 374px;
$mg-mobile-321: 321px;
$mg-desktop-1225: 1225px;
$mg-desktop-1125: 1125px;
$mg-desktop-1312: 1312px;

/*color*/
$bg-color-lightBlue: #e5ebff;
$bg-color-skyblue-light: #a8bdff;
$bg-color-skyblue: #5880ff;
$bittersweet: #ff6e6e;
$black-russian: #17181a;
$black-19: #19212a;
$blue-8: #87a4ff;
$blue-c: #ccd9ff;
$blue: #2f5fff;
$blue-new-home: #066afb;
$brd-color-lightBlue: #e5ebff;
$bronze: #ffa98a;
$coral-blue: #5a81ff;
$dark-blue: #2a54dd;
$f-color-black: #000;
$f-color-blue: #2f60ff;
$f-color-dark-black: #22252a;
$f-color-dark-blue: #2e5fff;
$f-color-dark2-blue: #00366f;
$f-color-light-black: #626669;
$f-color-light-blue: #8899cd;
$f-color-lightBlue: #6172a7;
$f-color-white: #fff;
$gold: #cea00d;
$lavender-light: #e7e8ef;
$ligh-royal-blue: #d0dbfc;
$light-pink-lace: #d0dbfe;
$light-purple: rgba(47, 96, 255, 0.5);
$light-red: #ff7064;
$rusty-red: #d82f44;
$light-blue: #e6edff;
$noble-light: #9b9b9b;
$nero: #282828;
$pink-lace: #8ba8ff;
$silver: #7997bb;
$snow: #f9f9f9;
$soft-blue: #96dbfa;
$tb-brd: #e8e8e8;
$tb-dash-brd: #efefef;
$white-smoke: #f1f1f1;
$white: #fff;
$charcol: #212529;
$light-blue: #cad6ee;
$dark-gun-metal: #20252c;
/*end*/

/*background color*/
$goalContainer-bg: #101317;
$f-bg-black: #000;
$f-bg-white: #fff;
$f-bg-light-white: #f9f9f9;
$f-bg-blue: #2f60ff;
$f-bg-light-blue: #e5ebff;
$f-color-g: #6d7278;
$f-color-90: #909099;
$f-color-8a: #8a8f9c;
$tranpr: transparent;
$transparant-black: rgba(0, 0, 0, 0.5);
$lightest-blue: #f6f9ff;
$light-shadow: rgba(0, 0, 0, 0.2);
$light-solitude: #f1f5ff;
$nobel: #a2a2a2;
$card-bg: rgba(16, 19, 23, 1);

// Green Shades
$green-1: #38cc9e;
$green-2: #c5ffcd;
$green-3: #8fec9d;
$green-4: #adff2f;
$green-5: #7fd18c;
$green-6: #5cf038;
$green-7: #88ec6f;

// Grey Shades
$grey-1: #bcbcbc;
$grey-2: #1F2123;
$grey-3: #969696;
$grey-4: #d1d1d1;
$grey-5: #9c9c9c;

// Red Shades
$red-1: #ff2f2f;

// Cyan Shades
$cyan-1: #c4fef7;

// Background
$frame-1: #1f2731;
$cards-1: #1981e1;
$cards-2: #101317;

// Shadows
$shadow-1: #000000;
$shadow-2: #2d3949;

// Global Settings

$namespace: 'sc';
$version: '1.0';

// Screens
$break-xs: 375px;
$break-small: 415px;
$break-medium: 768px;
$break-large: 1024px;
$break-xl: 1200px;
$break-xxl: 1400px;
$break-xxxl: 2560px;

// Font
/* font Variable */
$font-openSans: 'Open Sans';
$font-openSansB: 'Open Sans Bold';
$font-OpenSansSemi: 'Open Sans semi';
$openSansBold: 'Open Sans bold';
$Montserrat: 'Montserrat';

/*end font*/

/*font wight*/
$font4: 400;
$font6: 600;
$font7: 700;
$font5: 500;
$font-wtB: bold;
$font-wtN: normal;
$fontWeightNormal: normal;
$fontWeightBold: bold;
/*end font*/

/*float css*/
$left: left;
$right: right;
$none: none;
$center: center;
$justify: justify;
/*end font*/

$border: $tb-dash-brd;

// Vertical Heights
$h-100: 100vh;
$h-90: 90vh;
$h-80: 80vh;
$h-70: 70vh;
$h-60: 60vh;
$h-50: 50vh;
$h-40: 40vh;
$h-30: 30vh;
$h-20: 20vh;
$h-10: 10vh;
$h-0: 0vh;
