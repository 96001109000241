@mixin font($size, $color, $family, $weight) {
    font-size: $size;
    color: $color;
    font-family: $family;
    font-weight: $weight;
}

// TODO Remove Media Queries not used, unify similar

/*Media Query*/
@mixin for-mobile-mid-min {
    @media only screen and (min-width: $mg-mobile-mid) {
        @content;
    } /*min-width:568px*/
}

@mixin for-tablet-min {
    @media only screen and (min-width: $mg-tablet-min) {
        @content;
    } /*min-width:768px*/
}

@mixin for-tablet-up-mid {
    @media only screen and (min-width: $mg-tablet-mid) {
        @content;
    } /*min-width:992px*/
}

@mixin for-tablet-up-min {
    @media only screen and (min-width: $mg-tablet-max) {
        @content;
    } /*min-width:1024px*/
}

@mixin for-desktop-up-min {
    @media only screen and (min-width: $mg-desktop-mid) {
        @content;
    } /*min-width:1200px*/
}

@mixin for-desktop-max-in {
    @media only screen and (max-width: $mg-desktop-max-up) {
        @content;
    } /*max-width:1400px*/
}

@mixin for-desktop-max-up {
    @media only screen and (min-width: $mg-desktop-max-up) {
        @content;
    } /*min-width:1400px*/
}

@mixin for-mobile-810-up {
    @media only screen and (min-width: $mg-tablet-810) {
        @content;
    } /*min-width: 810px */
}
@mixin for-mobile-600 {
    @media only screen and (min-width: $mg-mobile-600) {
        @content;
    } /*min-width: 600px */
}
@mixin for-mobile-415 {
    @media only screen and (min-width: $mg-mobile-415) {
        @content;
    } /*min-width:415px*/
}

@mixin for-mobile-414-up {
    @media only screen and (min-width: $mg-mobile-414) {
        @content;
    } /*min-width:414px*/
}
@mixin for-mobile-375-up {
    @media only screen and (min-width: $mg-mobile-375) {
        @content;
    } /*min-width:375px*/
}

@mixin for-mobile-375-down {
    @media only screen and (max-width: $mg-mobile-375) {
        @content;
    } /*max-width:375px*/
}

@mixin for-big-Desk-1920 {
    @media only screen and (max-width: $mg-Bigdesktop-max-up) {
        @content;
    } /*max-width:1920px*/
}
@mixin for-tablet-1024-down {
    @media only screen and (max-width: $mg-tablet-max) {
        @content;
    } /*max-width:1024px*/
}
@mixin for-tablet-1023 {
    @media only screen and (max-width: $mg-tablet-1023) {
        @content;
    } /*max-width:1023px*/
}
@mixin for-mobile-321-down {
    @media only screen and (max-width: $mg-mobile-321) {
        @content;
    } /*max-width:321px*/
}
@mixin for-mobile-374-down {
    @media only screen and (max-width: $mg-mobile-374) {
        @content;
    } /*max-width:374px*/
}

@mixin for-mobile-475-down {
    @media only screen and (max-width: $mg-mobile-475) {
        @content;
    } /*max-width:374px*/
}

@mixin for-mobile-415-down {
    @media only screen and (max-width: $mg-mobile-415) {
        @content;
    } /*max-width:414px*/
}
@mixin for-mobile-max {
    @media only screen and (max-width: $mg-mobile-max) {
        @content;
    } /*max-width: 767px*/
}

@mixin for-mobile-992-max {
    @media only screen and (max-width: $mg-tablet-mid) {
        @content;
    } /*max-width: 992px*/
}

@mixin for-desktop-up {
    @media only screen and (max-width: $mg-desktop-mid) {
        @content;
    } /*max-width:1200px*/
}
@mixin for-mobile-576 {
    @media only screen and (max-width: $mg-mobile-576) {
        @content;
    } /*max-width: 576px */
}

@mixin for-mobile-567 {
    @media only screen and (max-width: $mg-mobile-567) {
        @content;
    } /*max-width: 567px */
}

@mixin for-mobile-600-down {
    @media only screen and (max-width: $mg-mobile-600) {
        @content;
    } /*max-width: 600px */
}

@mixin for-mobile-767-down {
    @media only screen and (max-width: $mg-mobile-767) {
        @content;
    } /*max-width: 767 */
}

@mixin for-mobile-481-down {
    @media only screen and (max-width: $mg-mobile-481) {
        @content;
    } /*max-width:481px*/
}

@mixin for-mobile-768-to-1199 {
    @media only screen and (min-width: $mg-tablet-min) and (max-width: $mg-desktop-1199) {
        @content;
    }
}

@mixin for-mobile-1199-down {
    @media only screen and (max-width: $mg-desktop-1199) {
        @content;
    } /*max-width: 1199 */
}

@mixin for-mobile-768-to-1083 {
    @media only screen and (min-width: $mg-tablet-min) and (max-width: $mg-desktop-1083) {
        @content;
    }
}

@mixin for-mobile-1024-to-1199 {
    @media only screen and (min-width: $mg-tablet-max) and (max-width: $mg-desktop-1199) {
        @content;
    }
}
@mixin for-mobile-769-to-1199 {
    @media only screen and (min-width: $mg-tablet-769) and (max-width: $mg-desktop-1199) {
        @content;
    }
}
@mixin for-mobile-768-to-1200 {
    @media only screen and (min-width: $mg-tablet-min) and (max-width: $mg-desktop-mid) {
        @content;
    }
}

@mixin for-desktop-1200-to-1380 {
    @media only screen and (min-width: $mg-desktop-mid) and (max-width: $mg-desktop-1380) {
        @content;
    }
}
@mixin for-desktop-1025-to-1225 {
    @media only screen and (min-width: $mg-desktop-min) and (max-width: $mg-desktop-1225) {
        @content;
    }
}
@mixin for-desktop-1025-to-1125 {
    @media only screen and (min-width: $mg-desktop-min) and (max-width: $mg-desktop-1125) {
        @content;
    }
}
@mixin for-desktop-768-to-1225 {
    @media only screen and (min-width: $mg-tablet-min) and (max-width: $mg-desktop-1225) {
        @content;
    }
}
@mixin for-desktop-769-to-1225 {
    @media only screen and (min-width: $mg-tablet-769) and (max-width: $mg-desktop-1225) {
        @content;
    }
}

@mixin for-big-Desk-1921-t0-3000 {
    @media only screen and (min-width: $mg-desktop-min) and (max-width: $mg-Bigdesktop-max) {
        @content;
    } /*min-width:1921px*/
}

@mixin for-desktop-1400-to-1920 {
    @media only screen and (min-width: $mg-desktop-max-up) and (max-width: $mg-desktop-1920) {
        @content;
    }
}

@mixin for-mobile-768-to-1180 {
    @media only screen and (min-width: $mg-tablet-min) and (max-width: $mg-desktop-1180) {
        @content;
    }
}
@mixin for-mobile-768-to-1024 {
    @media only screen and (min-width: $mg-tablet-min) and (max-width: $mg-tablet-max) {
        @content;
    }
}
@mixin for-mobile-768-to-1023 {
    @media only screen and (min-width: $mg-tablet-min) and (max-width: $mg-tablet-1023) {
        @content;
    }
}
@mixin for-mobile-568-to-767 {
    @media only screen and (min-width: $mg-mobile-mid) and (max-width: $mg-mobile-max) {
        @content;
    }
}
@mixin for-mobile-600-to-767 {
    @media only screen and (min-width: $mg-mobile-600) and (max-width: $mg-mobile-max) {
        @content;
    }
}
@mixin for-mobile-540-to-767 {
    @media only screen and (min-width: $mg-mobile-540) and (max-width: $mg-mobile-max) {
        @content;
    }
}
@mixin for-mobile-992-to-1180 {
    @media only screen and (min-width: $mg-mobile-992) and (max-width: $mg-desktop-1180) {
        @content;
    }
}
@mixin for-mobile-768-to-992 {
    @media only screen and (min-width: $mg-mobile-767) and (max-width: $mg-mobile-992) {
        @content;
    }
}
