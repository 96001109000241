@import './variable.scss';

*{
  margin: 0;
  padding: 0;
}

html{
  font-size: 62.5%;
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  width: 3px;
}

body::-webkit-scrollbar-track {
  width: 5px;
  background-color: rgb(99, 99, 99);
}

body::-webkit-scrollbar-thumb {
  width: 5px;
  background: rgba(28, 30, 51, 0.9);
  border-radius: 20px;
}

body {
  font-family: 'MontSerrat', sans-serif, -apple-system, BlinkMacSystemFont,
  'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
  'Droid Sans', 'Helvetica Neue';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $primary-bg;
  color: $primary-text;
}

@media(max-width:1050px){
  html{
    font-size: 54%;
  }
}

@media(max-width:900px){
  html{
    font-size: 52%;
  }
}

@media(max-width:720px){
  html{
    font-size: 42%;
  }
}

@media(max-width:480px){
  html{
    font-size: 34%;
  }
}

@media(max-width:310px){
  html{
    font-size: 27%;
  }
}

@media(max-width:200px){
  html{
    font-size: 18%;
  }
}