@import '../stylesheet/base.scss';
@import '../variable.scss';

body {
    font-family: 'Montserrat', sans-serif;
    font-weight: 100;
    max-width: 100%;
    // overflow-x: hidden;
}

.abel-bold {
    font-family: 'Abel';
    font-weight: 700;
}

.justified-content {
    text-align: justify;
    text-justify: inter-word;
}

a:link {
    text-decoration: none;
}

a:visited {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

a:active {
    text-decoration: none;
}

.overflow-x-hidden {
    overflow-x: hidden;
}

.overflow-x-auto {
    overflow-x: auto;
}

.overflow-y-scroll {
    overflow-y: scroll;
}

.overflow-clip {
    overflow: clip !important;
}

.padding-responsive {
    padding: 50px;
}

.cursor-pointer {
    cursor: pointer;
}

.full-body-black {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 2000;
}

.no-spacing {
    padding: 0;
    margin: 0;
}

.no-spacing-container > div {
    padding: 0;
    margin: 0;
}

.border-green {
    border: 1px solid greenyellow;
}

.border-black {
    border: 1px solid black;
}

.border-black-4 {
    border: 4px solid black;
}

.border-bottom-black-4 {
    border-bottom: 4px solid black;
}

.border-gray {
    border: 1px solid #dbdbdb;
}

.border-bottom-gray {
    border-bottom: 1px solid #dbdbdb;
}

.border-white {
    border: 1px solid white;
}

.height-50px {
    height: 50px;
}

.max-width-400px {
    max-width: 400px;
    width: 100%;
}

.stick-to-div-bottom {
    position: absolute;
    bottom: 0;
}

.h-44px {
    height: 44px;
}

.mh-600px {
    min-height: 600px;
}

.mw-90 {
    max-width: 90% !important;
}

.mt-afterNavbar {
    margin-top: 73.33px;
}

.mb-10 {
    margin-bottom: 100px !important;
}

.mb-20 {
    margin-bottom: 200px !important;
}

.margin-bottom-20 {
    margin-bottom: 20px !important;
}

.py-50l-30s {
    padding-top: 50px;
    padding-bottom: 50px;
}

.pb-10 {
    padding-bottom: 100px;
}

.pt-10 {
    padding-top: 100px;
}

.p-10 {
    padding-bottom: 100px;
    padding-top: 100px;
}
.pb-7 {
    padding-bottom: 70px !important;
}

.margin-responsive {
    margin: 50px !important;
}

.abel-title {
    font-family: 'Abel';
    font-size: 5rem;
    text-transform: uppercase;
}

.abel-title-small {
    padding: 50px;
    padding-top: 10px;
    font-family: 'Abel';
    font-size: 5rem;
    text-transform: uppercase;
}

.paragraph-text-responsive {
    font-size: 1.3rem;
}

.hover-scale {
    transition: all 0.3s ease;
}

.hover-scale:hover {
    transform: scale(1.05);
}

.td-100 {
    transform: translateY(100px);
}

.tu-100 {
    transform: translateY(-100px);
}

.td-80 {
    transform: translateY(80px);
}

.tu-80 {
    transform: translateY(-80px);
}

.tu-30 {
    transform: translateY(-30px);
}

.zi-1 {
    z-index: 1 !important;
}

.lh-1 {
    line-height: 1;
}

.mw-200px {
    min-width: 200px;
}

.w-mc {
    width: max-content;
}

.mh-44vw {
    min-height: 44vw !important;
}

.mh-80vh {
    min-height: 80vh !important;
}

.responsive-h-80vh {
    height: 80vh !important;
}

.mh-250px {
    min-height: 250px;
}

.mh-190px {
    min-height: 190px;
}

.fixed-bg {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height: 100vh;
    width: 100vw;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 0;
}

.text-gray {
    color: gray;
}

.text-black {
    color: black;
}

.padding-container-5 {
    padding: 50px;
}

.display-flex-animated {
    transform: translateX(0);
    animation: none-flex-translateX 1s;
    animation-iteration-count: 1;
}

.display-none-animated {
    transform: translateX(100vw);
    animation: flex-none-translateX 1s;
    animation-iteration-count: 1;
}

.display-none {
    display: none;
}

.display-block {
    display: block;
}

.in-view {
    animation: in-view-animation 1.2s;
    animation-iteration-count: 1;
}

.in-view-horizontal {
    animation: in-view-horizontal-animation 1.2s;
    animation-iteration-count: 1;
}

@keyframes in-view-horizontal-animation {
    0% {
        opacity: 0;
        transform: translateX(100px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes in-view-animation {
    0% {
        opacity: 0;
        transform: translateY(100px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}
@keyframes flex-none-translateX {
    0% {
        opacity: 1;
        display: flex !important;
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100vw);
        display: none !important;
        opacity: 0;
    }
}

@keyframes none-flex-translateX {
    0% {
        opacity: 0;
        display: flex !important;
        transform: translateX(+100vw);
    }
    100% {
        opacity: 1;
        display: flex !important;
        transform: translateX(0);
    }
}

/* .bottom-left-bo */

.background-bars {
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0px;
    bottom: 0px;
    /* transform: translate(5%, 5%); */
    background: repeating-linear-gradient(
        45deg,
        rgba(238, 238, 238, 0.7),
        rgba(238, 238, 238, 0.7) 5px,
        white 5px,
        white 15px
    );
}

/* Cover Component */

.we-text {
    font-weight: 600;
    animation: translate-top-bottom 7s infinite;
}

.cover-btn {
    animation: translate-bottom-top 7s infinite;
}

.cover-hashtag {
    position: relative;
    bottom: 0;
    left: 0;
    padding: 25px;
    padding-left: 30px;
}

.cover-content {
    min-height: 80vh !important;
    height: 100%;
}

.cover-image {
    padding-top: 10px;
    padding-bottom: 10px;
    top: 0px;
    left: 0px;
    min-height: calc(100vh - 65px);
    height: min-content;
    width: 100vw;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    z-index: 2;
}

.cover-image.bg-pos-inherit {
    background-position: inherit !important;
}

.cover-image-content {
    min-height: auto;
    height: 100%;
}

.design-div {
    width: 150px;
    position: absolute;
    border-left: 10px solid rgba(255, 255, 255, 0.25);
    border-top: 10px solid rgba(255, 255, 255, 0.25);
    border-bottom: 10px solid rgba(255, 255, 255, 0.25);
    height: 100%;
    animation: translate-left-right 7s infinite;
}

.changing-text {
    color: greenyellow;
}

.paragraph-text {
    font-weight: 400;
    color: white;
    padding: 5px;
    animation: translate-left-right 7s infinite;
}

@keyframes translate-left-right {
    0% {
        transform: translateX(100vw);
    }
    10% {
        transform: translateX(0);
    }

    90% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(100vw);
    }
}

@keyframes translate-top-bottom {
    0% {
        opacity: 0;
        transform: translateY(-100px);
    }
    5% {
        opacity: 1;
        transform: translateY(0);
    }
    90% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(-100px);
    }
}

@keyframes translate-bottom-top {
    0% {
        opacity: 0;
        transform: translateY(100px);
    }
    5% {
        opacity: 1;
        transform: translateY(0);
    }
    90% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(100px);
    }
}

/* Background Background */
.bg-black {
    background: black;
}

.bg-blacktanslucent {
    background: rgba(0, 0, 0, 0.5);
}

.bg-transparent {
    background: rgba(0, 0, 0, 0);
}

.bg-green {
    background: greenyellow;
}

.bg-transparent {
    background: rgba(0, 0, 0, 0);
}

.bg-gray-translucent {
    background: #100f11b0;
}

.bg-light-gray {
    background-color: #929292;
}

.bg-lightest-gray {
    background-color: #eeeeee;
}

.hover-light-gray:hover {
    background-color: #dfdddd;
}

.form-input,
.form-input-light,
.form-input-dark {
    background-color: transparent;
    border: 0px;
    border-bottom: 1px solid #38cc9e;
    color: $primary-text;
    padding: 10px;
    width: 100%;
}

.form-input-light {
    border-bottom: 1px solid black;
    color: black;
}

.form-input-light.full-border {
    border: 1px solid black;
}

.form-input-light::placeholder {
    color: #262626;
}

.form-input-dark {
    border-bottom: 1px solid #333333;
}

.form-input-dark::placeholder {
    color: #525252;
}

/**
Loading Image
**/

.placeholder-image-square {
    width: 100%;
    height: 0;
    padding-top: 100%;
}

.loading-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: max-content;
    bottom: 0;
    width: max-content;
    margin: auto;
}

.car-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 250px;

    @include for-mobile-1199-down {
        height: 200px;
    }

    @include for-mobile-767-down {
        height: 150px;
    }

    @include for-mobile-576 {
        height: 100px;
    }
}

.custom-modal-container {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0px;
    top: 0px;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 99999;
    overflow-y: scroll;
}

.modal-image-wrap {
    display: block !important;
    margin: 30px;
    margin-right: 40px;
    margin-left: 40px;
    padding: 0px;
}

.custom-modal {
    position: relative;
    background-color: black;
    height: calc(100vh - 100px);
    margin-top: 50px;
    margin-bottom: 50px;
    padding-top: 50px;
    padding-bottom: 50px;
    scroll-padding: 50px;
}

.custom-modal-content {
    padding-left: 10px;
    padding-right: 10px;
    height: 100%;
    overflow: auto;
}

/* width */
.custom-modal-content::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.custom-modal-content::-webkit-scrollbar-track {
    background: #333a28;
}

/* Handle */
.custom-modal-content::-webkit-scrollbar-thumb {
    background: greenyellow;
}

/* Handle on hover */
.custom-modal-content::-webkit-scrollbar-thumb:hover {
    background: #8ae600;
}

.custom-modal-close {
    position: absolute;
    right: 20px;
    top: 10px;
    cursor: pointer;
    transform: scale(1.5);
    z-index: 10000;
    transition: all 0.1s ease;
    color: #59984b;
}

.custom-modal-close:hover {
    color: greenyellow;
}

.hide-modal {
    display: none !important;
}

.show-modal {
    display: block;
    animation: show-modal-animation 0.4s normal;
}
.m-auto {
    margin: auto;
}
.mb-6 {
    margin: 0 auto 8px auto;
}

@keyframes show-modal-animation {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.02);
    }
    100% {
        transform: scale(1);
    }
}

.show-slidedown {
    display: block;
    animation: show-slidedown-animation 0.4s ease;
}

@keyframes show-slidedown-animation {
    0% {
        /* transform: scaleY(0); */
        transform: translateY(-100px);
    }
}

.date-box {
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 80px;
    width: 80px;
    background-color: rgba(0, 0, 0, 0.9);
    color: white;
    text-align: center;
}

.day-date-box {
    font-weight: 700;
    font-size: 40px;
}

.month-date-box {
    font-weight: 700;
    font-size: 20px;
    transform: translateY(-30px);
}

/* Status */

.status-back-logo-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.status-back-logo {
    opacity: 0.1;
    position: relative;
}

.status-text-container {
    transform: translateY(-7px);
}

.status-background {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.status-background-theme-2 {
    background-position: 10% 50%;
}

.status-background-theme-1 {
    background-position: 50% 50%;
}

.container-status {
    position: relative;
    background-color: #eeeeee;
    overflow: hidden;
}

.status-title {
    font-weight: 600;
    font-size: large;
}

.status-number {
    font-weight: 700;
    font-size: 3.5rem;
}

.status-img {
    height: 100px;
}

.side-text-horizontal {
    color: grey;
    transform: rotate(0deg);
    opacity: 0.1;
    font-size: 300px;
    transform-origin: 17% 70%;
    line-height: 90%;
}

/* Ask Us Anything */
.side-text-ask-us-anything {
    color: transparent;
    transform: translate(0, 120px);
    font-size: 140px;
    z-index: 0;
}

.ask-tabs {
    transition: all 0.3s ease;
}

.ask-tabs-content {
    overflow: hidden;
    transition: all 0.3s ease;
    opacity: 0;
}

.ask-tabs-content.active {
    opacity: 1;
}

.ask-tabs-vertical {
    min-height: 100px;
    height: 100px !important;
    width: 100%;
    transition: all 0.3s ease;
}

.ask-tabs-vertical.active {
    min-height: 400px;
}

/* WrapTextWithImage */

.square {
    min-height: 500px;
}

.image-wrap {
    max-width: calc(100% - 100px);
    max-height: 100%;
    width: 500px;
}
.image-wrap-container {
    max-width: 500px;
    max-height: 500px;
    margin-bottom: 50px;
    display: flex;
    position: relative;
    float: left;
}
/* Image Viewer */

.image-swap-container {
    background-color: black;
    height: auto !important;
}

.backgroun-blurred-image {
    position: absolute;
    height: 100%;
    width: 100%;
    filter: blur(5px);
    z-index: 0;
    overflow: hidden;
    opacity: 0.5;
}

.image-swap {
    max-width: 100%;
    max-height: 80vh;
    z-index: 1;
}
.left-half {
    position: absolute;
    left: 0px;
    height: 100%;
    width: 50%;
    z-index: 2;
}

.left-half:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.right-half {
    position: absolute;
    right: 0px;
    height: 100%;
    width: 50%;
    z-index: 2;
}

.right-half:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.left-arrow,
.right-arrow {
    position: absolute;
    z-index: 3;
    width: 90px;
    margin: 20px;
}

.left-arrow-wrap,
.right-arrow-wrap {
    width: 50px;
}

.right-arrow,
.right-arrow-wrap {
    right: 0px;
    animation: small-right-and-back 2s infinite;
}

.right-arrow:hover,
.right-arrow-wrap:hover {
    transform: translateX(10px);
}

.left-arrow,
.left-arrow-wrap {
    left: 0px;
    animation: small-left-and-back 2s infinite;
}
.left-arrow:hover,
.left-arrow-wrap:hover {
    transform: translateX(-10px);
}

.loading-bg {
    width: 100vw;
    height: 100vh;
    margin: 0px;
    padding: 0px;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 9999999;
}

.loading-text {
    transform: translateY(-50px);
}

@keyframes small-right-and-back {
    0% {
        transform: translateX(0px);
    }
    100% {
        transform: translateX(10px);
    }
}

@keyframes small-left-and-back {
    0% {
        transform: translateX(0px);
    }
    100% {
        transform: translateX(-10px);
        scale: (110%);
    }
}

/* Big Screen */

.big-screen-only {
    display: block;
}

@media only screen and (max-width: 400px) {
    .modal-image-wrap {
        margin: 0px !important;
        margin-bottom: 10px !important;
    }

    .abel-title {
        font-family: 'Abel';
        font-size: 3.5rem !important;
    }
}

@media only screen and (max-width: 575px) {
    .w-100-small {
        width: 100%;
    }

    .card-small {
        max-width: 100vw;
    }

    .responsive-h-80vh {
        height: 100vh !important;
    }

    .border-div {
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: transparent;
        transform: translateY(0px);
        border: 0px;
        /* border-bottom: 1px solid #262626; */
    }

    .padding-container-5 {
        padding: 20px !important;
    }

    .py-50l-30s {
        padding-bottom: 30px !important;
        padding-top: 30px !important;
    }

    .seven-day-title-big-workout {
        font-size: 15vw !important;
    }

    .seven-day-title-all-workout {
        font-size: 10vw !important;
    }

    .info-description {
        height: 50vh;
        max-height: 50vh;
    }

    .paragraph-text-responsive {
        font-size: 1.1rem;
    }

    .padding-responsive {
        padding: 0px;
    }

    .margin-responsive {
        margin: 0px !important;
    }

    .custom-modal-container {
        /* background: black; */
    }

    .custom-modal {
        margin-top: 0px;
        margin-bottom: 0px;
        height: 100vh;
    }

    .status-container > * {
        text-align: center !important;
    }

    .abel-title-small {
        font-size: 2.5rem;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .seven-day-subtitle {
        font-size: 1rem !important;
    }

    .status-number {
        font-size: x-large;
    }
    .status-title {
        font-size: medium;
    }
    .status-img {
        height: 80px;
    }
}

@media only screen and (max-width: 992px) {
    .carousel-card {
        height: auto !important;
        width: 90vw;
    }

    .small-info-item {
        background-color: #222222;
        color: white;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .small-info-item,
    .small-info-item:hover {
        color: white;
    }

    .small-info-container {
        background: #222222;
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .small-info-icons-sm {
        display: inline;
    }

    .small-info-icons-lg {
        display: none;
    }

    .small-info-icons {
        width: 70px;
        padding: 0px;
    }

    .small-info-backicon {
        width: 140px;
    }
    .side-text {
        font-size: 130px;
    }
    .side-text-right {
        -webkit-text-stroke-color: #ffffff;
        opacity: 0.2;
        right: -130px;
    }
    .status-back-logo-container {
        display: none !important;
    }
    .sticky-image-cover:not(.left) {
        display: none;
    }

    .sticky-image-cover.left {
        background-position: 40% top;
        filter: brightness(50%);
    }
}
