@import '../../../stylesheet/base.scss';
@import '../../../variable.scss';

.shadow-div {
    -moz-box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.8);
    -webkit-box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.8);
    box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.8);
    border-radius: 9px;

    @include for-mobile-767-down {
        -moz-box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.7);
        -webkit-box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.7);
        box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.7);
        border-radius: 9px;
    }
}

.shadow-div.shadow-div-vv-small {
    -moz-box-shadow: 0 0 1px 0px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0 0 1px 0px rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 1px 0px rgba(0, 0, 0, 0.05);
}

.border-large {
    border-radius: 20px !important;
    overflow: hidden;
}

.border-v-small {
    border-radius: 4px !important;
}

.shadow-container-title {
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
    padding-right: 5px;
    color: #5f5f5f;
    font-weight: 600;
    margin-bottom: 0;

    @include for-mobile-567 {
        font-size: 12px;
    }
}
