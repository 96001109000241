$black: black;
$white: white;
$grey-black: rgba(32, 33, 36, 1);
$light-grey: rgba(94, 94, 94, 1);
$green: rgba(56, 204, 158, 1);
$green-dull: rgba(56, 204, 158, 0.42);
$cyan: rgba(56, 204, 158, 0.24);

$primary-bg: $grey-black;
$secondary-bg: #FAFAFA;
$green-bg: $green-dull;
$cyan-bg: $cyan;
$primary-text: $white;
$green-text: $green;
$grey-text: $light-grey;
$black-text: $black;