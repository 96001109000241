.mainbox-404 {
    margin: auto;
    padding: 20px;
    max-width: 100%;
    width: max-content;
    height: max-content;
    position: relative;
}

.err-404 {
    color: greenyellow;
    font-family: 'Montserrat', sans-serif;
    font-size: 10rem;
    text-shadow: 2px 2px white;
}

.fa-404 {
    margin-top: 25%;
    color: greenyellow;
    font-size: 7rem;
    text-shadow: none;
}
.fa-question-circle {
    line-height: inherit !important;
}
/* Media-Queries */
/********** Medium devices only **********/
@media (min-width: 992px) and (max-width: 1199px) {
    .err-404 {
        font-size: 8rem;
    }
    .fa-404 {
        margin-top: 25%;
        font-size: 6rem;
    }
}

/********** Small devices only **********/
@media (min-width: 768px) and (max-width: 991px) {
    .err-404 {
        font-size: 7.8rem;
    }
    .fa-404 {
        margin-top: 26%;
        font-size: 5.6rem;
    }
}

/********** Extra small devices only **********/
@media (max-width: 767px) {
    .err-404 {
        font-size: 6rem;
    }
    .fa-404 {
        margin-top: 18%;
        font-size: 4.8rem;
    }
}

/********** Super extra small devices Only :-) (e.g., iPhone 4) **********/
@media (max-width: 479px) {
    .err-404 {
        font-size: 5rem;
    }
    .fa-404 {
        margin-top: 17%;
        font-size: 4rem;
    }
}
