@import '../../../variable.scss';
@import '../../../stylesheet/base.scss';

.plan-container{
    min-height: 100vh;
    background-color: $primary-bg;
    h1{
        padding: 7rem 0 5rem;
        color: $grey-text;
        font-size: 7rem;
        font-weight: 600;
        text-align: center;
    }
.main-PlanCard{
    align-items: center;
    justify-content: center;
    display: flex;
    // background-color: $secondary-bg;
    
    .plan-card {
        display: flex;
        color: $grey-black;
        flex-direction: row;
        @include for-mobile-992-max{
            flex-direction: column;
        }
        h3{
            color: white;
            font-size: 24px;
        }
    }
    .highlight{
        font-weight: $f-weight-5;
        font-size: $f-14;
    }
    .description{
        font-size: $f-12;
    }
    .program-type {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        font-size: $f-12;
        span{
            font-weight: $f-weight-5;
            .fontWeight{
                font-weight: $f-weight-4;
            }
        }
    }
    
}}
.modal-form{
    padding: 20px;
    // height: 220px;
    color: black;
    .displayMsg{
        color: red;
        padding: 5px;
        font-size: $f-10;
    }
    .column{
        padding: 5px;
        font-size: $f-12;
        font-weight: $f-weight-5;
        .asterisk{
            color: red;
        }
        input[type='text']{
            width: 170px;
            margin-left: 30px;
        }
        .applyButton{
            border: 1px solid gray;
            border-radius: 2px;
            padding: 2px 4px;
        }
        .applyButton:hover{
            background-color: gray;
            color: white;
            transition: ease-in 0.25s;
        }
    }
    
    .button-container{
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        .proceed-button{
            padding: 10px 20px;
            font-size: 16px;
            color: $black;
            font-weight: $font6;
            display: inline;
            text-align: center;
            border-radius: 4px;
        }
    }
}
