.hide{
    opacity: 0;
}

.fadeIn{
    animation: fadeIn 1s ease;
}

@keyframes fadeIn {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

.fadeOut{
    animation: fadeOut;
}

@keyframes fadeOut {
    0%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
}

.jumpIn{
    animation: jumpIn 1s ease;
}

@keyframes jumpIn {
    0%{
        transform: translateY(20vh) scale(0.6);
    }
    70%{
        transform: translateY(-2vh) scale(1.05);
    }
    100%{
    }
}

.slideIn{
    animation: slideIn ease;
}

@keyframes slideIn {
    0%{
        transform: translateX(100vw);
    }
    70%{
        transform: rotate(-5deg);
    }
    90%{
        transform: rotate(5deg);
    }
}
.dropTop{
    animation: dropTop 1.2s ease;
}

@keyframes dropTop{
    0%{
        transform: translateY(-5vh);
        opacity: 0.4;
    }
}

.bringUp{
    animation: bringUp;
}

@keyframes bringUp{
    0%{
        transform: translateY(10vh);
        opacity: 0.4;
    }
}

.imgDown{
    animation: imgDown 0.4s ease;
}

@keyframes imgDown{
    0%{
        transform: translateY(-4vh);
        opacity: 0.2;
    }
}

.imgUp{
    animation: imgUp 0.4s ease;
}

@keyframes imgUp{
    0%{
        transform: translateY(4vh);
        opacity: 0.2;
    }
}

.fadeText{
    animation: fadeText 0.2s ease;
}

@keyframes fadeText {
    0%{
        opacity: 0.4;
    }
}


// Unused

.zoomIn{
    animation: zoomIn;
}

@keyframes zoomIn {
    0%{
        opactity: 0.4;
        transform: scale(0.4);
    }
    80%{
        transform: scale(1.1);
    }
    100%{
    }
}

@keyframes wave{
    50%{
        transform: rotate(20deg) translateX(5rem);
    }
}
